import moment, { Moment } from "moment";
import React, { useState, useCallback, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import {
  convertToMomentWithTimezone,
  formatDateTime,
  getTimezone,
} from "../../../../../Util";

interface InputProps {
  name: string;
  initialValue: Date;
  onSubmit: (data: { name: string; value: string }) => void;
}

const DatePickerReact: any = ReactDatePicker as any;

const AuctionDateInput: React.FC<InputProps> = ({
  name,
  initialValue,
  onSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [lotDate, setLotDate] = useState<Moment | null>(
    convertToMomentWithTimezone(initialValue, getTimezone()),
  );
  const [hour, setHour] = useState(lotDate?.hour() || 0);
  const [minute, setMinute] = useState(lotDate?.minute() || 0);

  useEffect(() => {
    if (lotDate) {
      setHour(lotDate.hour());
      setMinute(lotDate.minute());
    }
  }, [lotDate]);

  const handleEditClick = () => setIsEditing(true);

  const handleCancelClick = () => {
    setIsEditing(false);
    setLotDate(convertToMomentWithTimezone(initialValue, getTimezone()));
    setHour(lotDate?.hour() || 0);
    setMinute(lotDate?.minute() || 0);
  };

  const handleSaveClick = () => {
    if (lotDate) {
      const updatedDate = lotDate.clone().set({ hour, minute, second: 0 });
      onSubmit({
        name,
        value: convertToMomentWithTimezone(
          updatedDate.toDate(),
          "UTC",
        ).format(),
      });
      setLotDate(updatedDate);
    }
    setIsEditing(false);
  };

  return (
    <div
      className="field is-flex is-align-items-center"
      style={{ gap: "0.5rem" }}
    >
      {isEditing ? (
        <div
          className="is-flex is-align-items-center"
          style={{ gap: "0.25rem" }}
        >
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <div className="control is-expanded">
                  <DatePickerReact
                    className="input is-small"
                    selected={lotDate ? lotDate.toDate() : new Date()}
                    onChange={(date: Date) => setLotDate(moment(date))}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control is-expanded">
                  <input
                    id="auctionStartsAtHour"
                    className="input is-small"
                    type="number"
                    min="0"
                    max="23"
                    value={hour}
                    onChange={(e) => setHour(Number(e.target.value))}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control is-expanded">
                  <input
                    id="auctionStartsAtMinute"
                    className="input is-small"
                    type="number"
                    min="0"
                    max="59"
                    value={minute}
                    onChange={(e) => setMinute(Number(e.target.value))}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="button is-success is-small"
            onClick={handleSaveClick}
            title="Save"
            style={{ padding: "8px" }}
          >
            <span className="icon is-small">
              <i className="fas fa-save"></i>
            </span>
          </button>
          <button
            className="button is-danger is-small"
            onClick={handleCancelClick}
            title="Cancel"
            style={{ padding: "8px" }}
          >
            <span className="icon is-small">
              <i className="fas fa-times"></i>
            </span>
          </button>
        </div>
      ) : (
        <div
          className="is-flex is-align-items-center"
          style={{ gap: "0.25rem" }}
        >
          <p style={{ marginBottom: 0, fontWeight: 500 }}>
            {lotDate ? formatDateTime(lotDate.toDate()) : ""}
          </p>
          <button
            className="button is-link is-light is-small"
            onClick={handleEditClick}
            style={{ border: "none", backgroundColor: "transparent" }}
            title="Edit"
          >
            <span
              className="icon is-small"
              style={{ fontSize: "11px", marginBottom: "5px" }}
            >
              <i className="fas fa-pencil-alt" style={{ color: "grey" }}></i>
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default AuctionDateInput;
