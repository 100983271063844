"use client";

import { Flex, Pagination, Space, Table as AntDTable } from "antd";
import useReplaceQueryParams from "../../utils/useReplaceQueryParams";
import { MetaResult } from "../../generated/types";
import React from "react";

type TableProps = {
  dataSource: any;
  columns: any[];
  meta?: MetaResult | null;
  rowKey: string | ((record: any) => string);
  onChange?: any;
  loading?: boolean;
};

const Table = (props: TableProps) => {
  const { push } = useReplaceQueryParams();

  const onChange = (page: number) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    push({ page });
  };

  const handleOnChange = (props.onChange
    ? props.onChange
    : onChange) as unknown as any;

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <AntDTable
        {...props}
        pagination={false}
        className="mb-8"
        scroll={{ x: "max-content" }}
      />
      {props.meta && (
        <Flex justify="end">
          <Pagination
            defaultCurrent={props.meta.page}
            total={props.meta.total_pages * props.meta.per_page}
            pageSize={props.meta.per_page}
            onChange={handleOnChange}
            showSizeChanger={false}
          />
        </Flex>
      )}
    </Space>
  );
};

export default Table;
