import Table from "../../Components/layouts/table";
import React from "react";
import usePastLotsTable from "./usePastLotsTable";
import { Card } from "antd";
import FormModal from "../../Components/FormModal";
import ConfirmationModal from "../../Components/ConfirmationModal";

const PastLotsTable = () => {
  const {
    pastLots,
    meta,
    isLoading,
    columns,
    onChange,
    reorderPastLots,
    setReorderPastLots,
    updatePastLotsPositions,
    pastLotThumbnailToUpdate,
    pastLotDetailImageToUpdate,
    onCancelThumbnail,
    onConfirmThumbnail,
    pastLotVideoToUpdate,
    setPastLotVideoToUpdate,
    updatePastLotVideo,
    pastLotToDelete,
    deletePastLot,
    setPastLotToDelete,
    pastLotToUpdate,
    setPastLotToUpdate,
    updatePastLot,
  } = usePastLotsTable();

  return (
    <>
      <ConfirmationModal
        bodyText={
          "Are you sure you want to remove this lot from the list of Past Lots?"
        }
        confirmText="Yes"
        cancelText="No"
        visible={pastLotToDelete !== null}
        onConfirm={() => deletePastLot(pastLotToDelete!)}
        onCancel={() => setPastLotToDelete(null)}
      />
      <FormModal
        form={[
          {
            label: "Enter sold price",
            inputPlaceholder:
              (pastLotToUpdate && pastLotToUpdate.soldPrice) || undefined,
          },
          { label: "Enter date at when the lot was sold", inputType: "date" },
        ]}
        confirmText="Update"
        visible={pastLotToUpdate !== null}
        onCancel={() => setPastLotToUpdate(null)}
        onConfirm={(soldPrice, soldOn) =>
          updatePastLot(pastLotToUpdate!, soldOn, soldPrice)
        }
      />
      <FormModal
        form={[
          {
            label:
              pastLotThumbnailToUpdate !== null ? "Thumbnail" : "Detail Image",
            inputType: "file",
            required: true,
          },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={
          pastLotThumbnailToUpdate !== null ||
          pastLotDetailImageToUpdate !== null
        }
        confirmText="Save"
        onCancel={onCancelThumbnail}
        onConfirm={onConfirmThumbnail}
      />

      <FormModal
        form={[
          { label: "Vimeo ID", inputType: "text", required: true },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={pastLotVideoToUpdate !== null}
        confirmText="Save"
        onCancel={() => setPastLotVideoToUpdate(null)}
        onConfirm={(videoId, width, height) =>
          updatePastLotVideo(pastLotVideoToUpdate!, videoId, +width, +height)
        }
      />
      <Card>
        <h2 className="subtitle">Past Lots</h2>
        <div className="buttons is-right">
          {reorderPastLots ? (
            <>
              <button
                className="button is-danger is-light"
                onClick={() => setReorderPastLots(false)}
              >
                Cancel
              </button>
              <button
                className="button is-primary"
                onClick={updatePastLotsPositions}
              >
                Save Ordering
              </button>
            </>
          ) : (
            <>
              <button
                className="button"
                onClick={() => setReorderPastLots(true)}
              >
                Reorder
              </button>
            </>
          )}
        </div>
      </Card>
      <Table
        dataSource={pastLots}
        columns={columns}
        rowKey="id"
        meta={meta}
        loading={isLoading}
        onChange={onChange}
      />
    </>
  );
};

export default PastLotsTable;
