"use client";

import React from "react";
import InviteCodesPage from "../../Components/Pages/InviteCodesPage";

const InviteCodesNextPage = () => {
  return <InviteCodesPage />;
};

export default InviteCodesNextPage;
