import React, { useState } from "react";
import * as backend from "../Backend";
import ConfirmationModal from "./ConfirmationModal";

const AuctionRoomAlerts = () => {
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [soldPrice, setSoldPrice] = useState("");

  const sendAlert = async (body: any) => {
    setDisabled(true);

    await backend.auction.createAlert(body);

    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  const vibrate = () => {
    sendAlert({
      vibrate: true,
    });
  };

  const bell = () => {
    sendAlert({
      vibrate: true,
      bell: true,
    });
  };

  const hammer = () => {
    sendAlert({
      vibrate: true,
      hammer: true,
    });
  };

  const fairWarning = () => {
    sendAlert({
      text: "FAIR WARNING",
      backgroundColor: [
        "rgba(185, 12, 154, 0.3)",
        "rgba(229, 58, 58, 0.6)",
        "rgba(230, 122, 0, 0.6)",
      ],
      fontSize: 45,
      displayTimeMS: 1000,
      vibrate: true,
    });
  };

  const lastCall = () => {
    sendAlert({
      text: "LAST CHANCE",
      backgroundColor: ["rgba(3, 30, 227, 0.8)", "rgba(180, 2, 172, 0.5)"],
      fontSize: 45,
      displayTimeMS: 1000,
      vibrate: true,
    });
  };

  const bidSplit = () => {
    sendAlert({
      text: "BID SPLIT",
      backgroundColor: ["rgba(3, 30, 227, 0.8)", "rgba(180, 2, 172, 0.5)"],
      fontSize: 45,
      displayTimeMS: 1000,
      vibrate: true,
    });
  };

  const welcome = () => {
    sendAlert({
      text: "WELCOME \n NEW BIDDER",
      backgroundColor: ["rgba(164, 123, 255, 0.8)", "rgba(100, 17, 141, 0.5)"],
      fontSize: 45,
      displayTimeMS: 1000,
    });
  };

  const sold = () => {
    sendAlert({
      text: "SOLD",
      backgroundColor: ["rgba(46,188,179,0.8)", "rgba(247,181,0,0.6)"],
      fontSize: 45,
      displayTimeMS: 3000,
      vibrate: true,
    });
  };

  const endAuction = () => {
    setShowModal(false);
    setSoldPrice("");
    sendAlert({
      action: "endAuction",
      soldPrice: soldPrice,
    });
  };

  const ten = () => {
    sendAlert({
      text: "10",
      backgroundColor: ["rgba(137, 151, 255, 0.8)", "rgba(37, 37, 37, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const nine = () => {
    sendAlert({
      text: "09",
      backgroundColor: ["rgba(137, 151, 255, 0.8)", "rgba(37, 37, 37, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const eight = () => {
    sendAlert({
      text: "08",
      backgroundColor: ["rgba(137, 151, 255, 0.8)", "rgba(37, 37, 37, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const seven = () => {
    sendAlert({
      text: "07",
      backgroundColor: ["rgba(164, 123, 255, 0.8)", "rgba(100, 17, 141, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const six = () => {
    sendAlert({
      text: "06",
      backgroundColor: ["rgba(74, 102, 255, 0.8)", "rgba(164, 123, 255, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const five = () => {
    sendAlert({
      text: "05",
      backgroundColor: ["rgba(137, 255, 187, 0.8)", "rgba(137, 232, 255, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const four = () => {
    sendAlert({
      text: "04",
      backgroundColor: ["rgba(255, 248, 137, 0.8)", "rgba(137, 255, 187, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const three = () => {
    sendAlert({
      text: "03",
      backgroundColor: ["rgba(255, 129, 53, 0.8)", "rgba(255, 248, 137, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const two = () => {
    sendAlert({
      text: "02",
      backgroundColor: ["rgba(255, 80, 165, 0.8)", "rgba(255, 129, 53, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  const one = () => {
    sendAlert({
      text: "01",
      backgroundColor: ["rgba(255, 80, 80, 0.8)", "rgba(255, 80, 129, 0.5)"],
      fontSize: 100,
      displayTimeMS: 1000,
    });
  };

  return (
    <>
      <ConfirmationModal
        bodyText={"Are you sure you want to END auction?"}
        confirmText="End Auction"
        visible={showModal}
        onConfirm={endAuction}
        onCancel={() => setShowModal(false)}
      />
      <div className="buttons are-large">
        <button
          className="button is-primary"
          disabled={disabled}
          onClick={vibrate}
        >
          Vibrate
        </button>

        <button
          className="button is-primary"
          disabled={disabled}
          onClick={bell}
        >
          Bell
        </button>

        <button
          className="button is-primary"
          disabled={disabled}
          onClick={hammer}
        >
          Hammer
        </button>
      </div>
      <div className="buttons are-large">
        <button
          className="button is-primary"
          disabled={disabled}
          onClick={welcome}
        >
          Welcome
        </button>
      </div>
      <div className="buttons are-large">
        <button
          className="button is-info"
          disabled={disabled}
          onClick={bidSplit}
        >
          Bid Split
        </button>
      </div>
      <div className="buttons are-large">
        <button
          className="button is-warning"
          disabled={disabled}
          onClick={lastCall}
        >
          Last Chance
        </button>
      </div>
      <div className="buttons are-large">
        <button
          className="button is-danger"
          disabled={disabled}
          onClick={fairWarning}
        >
          Fair Warning
        </button>
      </div>
      <div>
        <div className="buttons are-large">
          <button className="button is-info" disabled={disabled} onClick={ten}>
            10
          </button>
          <button className="button is-info" disabled={disabled} onClick={nine}>
            9
          </button>
          <button
            className="button is-info"
            disabled={disabled}
            onClick={eight}
          >
            8
          </button>
          <button
            className="button is-info"
            disabled={disabled}
            onClick={seven}
          >
            7
          </button>
          <button className="button is-info" disabled={disabled} onClick={six}>
            6
          </button>
          <button className="button is-info" disabled={disabled} onClick={five}>
            5
          </button>
          <button className="button is-info" disabled={disabled} onClick={four}>
            4
          </button>
          <button
            className="button is-info"
            disabled={disabled}
            onClick={three}
          >
            3
          </button>
          <button className="button is-info" disabled={disabled} onClick={two}>
            2
          </button>
          <button className="button is-info" disabled={disabled} onClick={one}>
            1
          </button>
        </div>
        <div className="buttons are-large">
          <button
            className="button is-success"
            disabled={disabled}
            onClick={sold}
          >
            Sold
          </button>
        </div>
        <div className="columns">
          <div className="column is-half">
            <input
              type="number"
              className="input"
              value={soldPrice}
              min={0}
              onChange={(e) => setSoldPrice(e.target.value)}
            />
          </div>
          <div className="buttons are-large">
            <button
              className="button is-success"
              disabled={soldPrice === ""}
              onClick={() => setShowModal(true)}
            >
              End Auction
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionRoomAlerts;
