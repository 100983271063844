import { useCallback } from "react";
import * as backend from "../../../Backend";
import { useToast } from "../../../providers/notification.provider";

const useLot = (lotId: string) => {
  const toast = useToast();
  const updateLot = useCallback(
    async (data: { name: string; value: string | boolean | object }) => {
      await backend.lot.update(lotId, {
        lot: {
          [data.name]: data.value,
        },
      });

      toast({ type: "good", message: "Lot updated" });
    },
    [toast, lotId],
  );

  const onChangeSlug = (value: any) => {
    if (!/^[a-z0-9-]+$/.test(value)) {
      return value.replace(/[^a-z0-9-]/g, "");
    }

    return value;
  };

  return {
    updateLot,
    onChangeSlug,
  };
};

export default useLot;
