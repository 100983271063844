import { createSearchParams, useSearchParams } from "./navigationUtils";

const useReplaceQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let previousParams: any = {};

  searchParams.forEach((value, key) => {
    previousParams[key] = value;
  });

  const push = (obj: any) => {
    setSearchParams(
      createSearchParams({ ...previousParams, ...obj }).toString(),
    );
  };

  return {
    push,
  };
};

export default useReplaceQueryParams;
