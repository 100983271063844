import {
  Link as ReactRouterDomLink,
  useLocation as useReactRouterDomLocation,
  useNavigate as useReactRouterDomNavigate,
  useParams as useReactRouterDomParams,
  createSearchParams as createReactRouterDomSearchParams,
  useSearchParams as useReactRouterDomSearchParams,
} from "react-router-dom";

export const Link = ReactRouterDomLink;
export const useLocation = useReactRouterDomLocation;
export const useNavigate = useReactRouterDomNavigate;
export const useParams = useReactRouterDomParams;
export const createSearchParams = createReactRouterDomSearchParams;
export const useSearchParams = useReactRouterDomSearchParams;
