import React from "react";
import { Tag } from "antd";
import { ReferralCode } from "../../generated/types";
import { put } from "../../utils/http";

const ActionCell = (onLoadData: any) => (_: any, record: ReferralCode) => {
  const onUpdate = async (params: {}) => {
    await put(`/referral_codes/${record.id}`, params);
    onLoadData();
  };

  const onChangeActive = () => onUpdate({ active: !record.active });
  const onChangeAutoAccept = () => onUpdate({ autoAccept: !record.autoAccept });

  return (
    <>
      <Tag className="cursor-pointer" color="default" onClick={onChangeActive}>
        Change Active
      </Tag>
      <Tag
        className="cursor-pointer"
        color="default"
        onClick={onChangeAutoAccept}
      >
        Change Auto-Accept
      </Tag>
    </>
  );
};

export default ActionCell;
