"use client";

import React from "react";
import UsersPage from "../../Components/Pages/UsersPage";

const UsersNextPage = () => {
  return <UsersPage />;
};

export default UsersNextPage;
