"use client";

import React from "react";
import VipPage from "../../Components/Pages/VipPage";

const VipNextPage = () => {
  return <VipPage />;
};

export default VipNextPage;
