import React from "react";

interface NewComparableProps {
  showNewComparable: boolean;
  setShowNewComparable: (show: boolean) => void;
  setNewComparable: (comparable: File | undefined) => void;
  newComparableDescription: string;
  setNewComparableDescription: (description: string) => void;
  newComparableRow: string;
  setNewComparableRow: (row: string) => void;
  saving: boolean;
  uploadComparable: () => void;
}

const NewComparable: React.FC<NewComparableProps> = ({
  showNewComparable,
  setShowNewComparable,
  setNewComparable,
  newComparableDescription,
  setNewComparableDescription,
  newComparableRow,
  setNewComparableRow,
  saving,
  uploadComparable,
}) => {
  return (
    <div className={`modal ${showNewComparable ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => setShowNewComparable(false)}
      />
      <div className="modal-content">
        <div className="box">
          <div>Upload new image (png/jpg)</div>
          <div>
            <input
              type="file"
              onChange={(e) => setNewComparable(e.target.files?.[0])}
            />
          </div>
          <div>
            Description:
            <input
              type="text"
              value={newComparableDescription}
              onChange={(e) => setNewComparableDescription(e.target.value)}
            />
            <div>
              <label htmlFor="">
                Row:
                <select
                  value={newComparableRow}
                  defaultValue={"1"}
                  onChange={(e) => {
                    setNewComparableRow(e.target.value);
                  }}
                >
                  <option value="1">row 1</option>
                  <option value="2">row 2</option>
                </select>
              </label>
            </div>
          </div>
          {saving ? (
            <div style={{ marginTop: "20px" }}>
              <progress className="progress is-small is-primary" max="100">
                40%
              </progress>
            </div>
          ) : (
            <div className="buttons is-right">
              <button
                className="button is-primary"
                onClick={() => uploadComparable()}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
      <button
        className="modal-close is-large"
        onClick={() => setShowNewComparable(false)}
      />
    </div>
  );
};

export default NewComparable;
