"use client";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import React from "react";
import { ReferralCode } from "../../generated/types";
import ActionCell from "./ActionCell";
import Table from "../../Components/layouts/table";
import useDataLoader from "../../utils/useDataLoader";
import { Link } from "../../utils/navigationUtils";

const params = {
  with_referrer: "true",
};

const dataLoaderParams = {
  path: "/referral_codes",
  queryParams: ["page"],
  params,
};

const UserCell = (_: any, record: ReferralCode) => (
  <Link to={`/users/${record.referrer.id}`}>{record.referrer.fullName}</Link>
);

const ReferralCodesNextPage = () => {
  const {
    data: referralCodes,
    meta,
    onLoadData,
  } = useDataLoader<ReferralCode[]>(dataLoaderParams);

  const columns = [
    { title: "Code", dataIndex: "code", key: "code" },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active: boolean) =>
        active ? <CheckOutlined /> : <CloseOutlined />,
    },
    {
      title: "Auto-Accept",
      dataIndex: "autoAccept",
      key: "autoAccept",
      render: (active: boolean) =>
        active ? <CheckOutlined /> : <CloseOutlined />,
    },
    { title: "Users Count", dataIndex: "usersCount", key: "usersCount" },
    { title: "Referrer", key: "referrer", render: UserCell },
    { title: "Actions", key: "actions", render: ActionCell(onLoadData) },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={referralCodes}
      meta={meta}
    />
  );
};

export default ReferralCodesNextPage;
