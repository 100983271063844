import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as backend from "../../Backend";
import { useToast } from "../../providers/notification.provider";
import {
  checkAdmin,
  configureBackend,
  UserContext,
} from "../../providers/user_provider";
import { signIn, signOut } from "../../firebase/auth/signInOut";

export function SignInPage() {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const {
    formState: { isSubmitting: isCodeSubmitting },
    register: codeRegister,
    handleSubmit: handleCodeSubmit,
    reset: resetCode,
  } = useForm();

  const [user, setUser] = useState<any | null>(null);
  const { setUser: setAppUser } = useContext(UserContext);

  const onSignIn = async (user: any, mfaToken: any) => {
    localStorage.setItem("mfaToken", mfaToken);
    configureBackend(mfaToken);
    const admin = await checkAdmin(user);

    if (admin) {
      toast({ type: "good", message: "Successfully signed in." });
      setAppUser(user);
    } else {
      await backend.user.signOut();
      localStorage.removeItem("mfaToken");
      await signOut();
      toast({ type: "bad", message: "Unauthorized" });
      reset();
      resetCode();
      setUser(null);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const { result } = await signIn(data.email, data.password);
      const r = result as unknown as any;
      await requestPhone(r.user);
      reset();
      setUser(r.user);
    } catch (e) {
      toast({ type: "bad", message: "Invalid username/password" });
    }
  };

  const requestPhone = async (user: any) => {
    try {
      await backend.user.mfaRequest(user);
    } catch (e) {
      setUser(null);
      toast({ type: "bad", message: "Invalid username/password" });
    }
  };

  const onCodeSubmit = async (data: any) => {
    try {
      const result = await backend.user.confirmMfa(user!, data);
      resetCode();

      if (!!result.data.id) {
        await onSignIn(user!, result.data?.attributes?.token);
      }
    } catch (e) {
      toast({ type: "bad", message: "Incorrect code" });
    }
  };

  return (
    <div className="columns">
      <div className="column" />
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">Sign In</div>
          <div className="panel-block">
            <div className="container">
              {user ? (
                <form onSubmit={handleCodeSubmit(onCodeSubmit)}>
                  <div className="field">
                    <label htmlFor="code" className="label">
                      Code
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        {...codeRegister("code", { required: true })}
                      />
                      {errors.code && (
                        <p className="help is-danger">Code incorrect</p>
                      )}
                    </div>
                  </div>

                  <button
                    className="button is-success"
                    type="submit"
                    disabled={isCodeSubmitting}
                  >
                    Confirm Code
                  </button>
                </form>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <label htmlFor="email" className="label">
                      Email
                    </label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        {...register("email", { required: true })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope" />
                      </span>
                      {errors.email && (
                        <p className="help is-danger">Email required</p>
                      )}
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="password" className="label">
                      Password
                    </label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="password"
                        {...register("password", { required: true })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock" />
                      </span>
                      {errors.password && (
                        <p className="help is-danger">Password required</p>
                      )}
                    </div>
                  </div>

                  <button
                    className="button is-success"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Sign In
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="column" />
    </div>
  );
}
