"use client";

import React from "react";
import FeaturesPage from "../../Components/Pages/FeaturesPage";

const FeaturesNextPage = () => {
  return <FeaturesPage />;
};

export default FeaturesNextPage;
