import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { videoTag } from "../..";

const ComparableItem: React.FC<{
  comparable: any;
  index: number;
  reorderComparables: boolean;
  comparablesPositions: { [key: string]: string | number };
  setComparablesPositions: (position: any) => void;
  setVimeoVideo: (video: any) => void;
  setNewVimeoForComparableAt: (index: number) => void;
  onEditComparable: (comparable: any) => void;
  setVimeoDeleteAction: (video: Array<any>) => void;
  deleteLotComparableVimeo: (comparable: any) => void;
  onDeleteComparable: (comparable: any) => void;
}> = ({
  comparable,
  index,
  reorderComparables,
  comparablesPositions,
  setComparablesPositions,
  setVimeoVideo,
  setNewVimeoForComparableAt,
  onEditComparable,
  setVimeoDeleteAction,
  deleteLotComparableVimeo,
  onDeleteComparable,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: comparable.id,
      resizeObserverConfig: {
        disabled: false,
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  };

  return (
    <div
      key={comparable.id}
      className="column is-one-fifth"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="card">
        <div className="card-image">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img alt="" src={comparable.fileUrl} className="img-draggable" />
        </div>
        <div className="card-content">
          <div>
            Position{" "}
            {reorderComparables ? (
              <input
                type="text"
                value={comparablesPositions[comparable.id]}
                onChange={(e) => {
                  setComparablesPositions({
                    ...comparablesPositions,
                    [comparable.id]: e.target.value,
                  });
                }}
              />
            ) : (
              <span>{index}</span>
            )}
            <div>Row: {comparable.row}</div>
            <div>Type: {comparable.fileType}</div>
          </div>
          <div>{comparable.description}</div>
          <div style={{ paddingBottom: "5px" }}>
            {videoTag(comparable.vimeoVideo?.id)}
          </div>
          {
            <div className="buttons">
              {comparable.vimeoVideo ? (
                <button
                  className="button is-primary is-light"
                  onClick={() =>
                    comparable.vimeoVideo
                      ? setVimeoVideo(comparable.vimeoVideo)
                      : setNewVimeoForComparableAt(index)
                  }
                >
                  Play Vimeo
                </button>
              ) : null}
              <button
                className="button is-warning is-light"
                onClick={() => setNewVimeoForComparableAt(index)}
              >
                Change Vimeo
              </button>
              {comparable.vimeoVideo ? (
                <button
                  className="button is-danger is-light"
                  onClick={() =>
                    setVimeoDeleteAction([deleteLotComparableVimeo, comparable])
                  }
                >
                  Delete Vimeo
                </button>
              ) : null}
              <button
                className="button is-danger"
                onClick={() => onDeleteComparable(comparable)}
              >
                Delete
              </button>
              <button
                className="button is-warning"
                onClick={() => onEditComparable(comparable)}
              >
                Edit
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ComparableItem;
