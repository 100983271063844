import React from "react";
import { Lot } from "../../../../generated/types";
import useMediasSection from "./useMediasSection";
import MediaForm from "../../../MediaForm";

interface ComparablesSectionProps {
  lot: Lot;
  loadLot: () => void;
}

const MediasSection: React.FC<ComparablesSectionProps> = ({ lot, loadLot }) => {
  const {
    showMedias,
    setShowMedias,
    showNewMedia,
    setShowNewMedia,
    onAddMedia,
    onDeleteMedia,
    onSubmitMedia,
    currentMedia,
  } = useMediasSection(lot, loadLot);

  return (
    <>
      {showNewMedia && (
        <div className={`modal ${showNewMedia ? "is-active" : ""}`}>
          <div
            className="modal-background"
            onClick={() => setShowNewMedia(false)}
          />
          <div className="modal-content">
            <div className="box">
              <MediaForm media={currentMedia} onSubmit={onSubmitMedia} />
            </div>
          </div>
        </div>
      )}
      <h2>
        PDF section{" "}
        <span className="as_link" onClick={() => setShowMedias(!showMedias)}>
          {showMedias ? "Hide" : "Show"}
        </span>
      </h2>

      {showMedias && (
        <div className="box">
          {lot.medias &&
            lot.medias.map((media) => (
              <div key={media.id}>
                <div className="columns">
                  <div className="column">
                    <strong>Type</strong>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="is-linewrap">{media.type || "-"}</div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <strong>Title</strong>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="is-linewrap">{media.title || "-"}</div>
                  </div>
                </div>
                {/* <div className="columns">
                    <div className="column">
                      <strong>Description</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">
                        {media.description || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>Url</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">{media.url || "-"}</div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>Thumbnail Url</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">
                        {media.thumbnailUrl || "-"}
                      </div>
                    </div>
                  </div> */}
                <div className="columns">
                  <div className="column">
                    <strong>File</strong>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="is-linewrap">{media.fileUrl || "-"}</div>
                  </div>
                </div>
                {/* <div className="columns">
                    <div className="column">
                      <strong>Configuration</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">
                        {media.configuration || "-"}
                      </div>
                    </div>
                  </div> */}

                <div className="buttons is-right">
                  <button
                    className="button is-warning"
                    onClick={() => onAddMedia(media)}
                  >
                    Edit
                  </button>
                  <button
                    className="button is-danger"
                    onClick={() => onDeleteMedia(media)}
                  >
                    Delete
                  </button>
                </div>
                <hr />
              </div>
            ))}

          <div className="buttons is-right">
            <button className="button" onClick={() => onAddMedia(null)}>
              Add
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MediasSection;
