import React, { useRef, useState } from "react";

interface FormModalProps {
  image: any | null;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onConfirm: (image: File, thumbnailImage: File) => void;
}

const UploadThumbnailModal: React.FC<FormModalProps> = ({
  image,
  visible,
  setVisible,
  onConfirm,
}) => {
  const [newThumbnailImage, setNewThumbnailImage] = useState<File | undefined>(
    undefined,
  );
  const [saving, setSaving] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleConfirm = async () => {
    if (!newThumbnailImage) {
      return;
    }
    setSaving(true);
    try {
      await onConfirm(image, newThumbnailImage);
      handleClose();
    } catch (error) {
      setSaving(false);
    } finally {
      setSaving(false);
    }
  };

  const handleClose = () => {
    setNewThumbnailImage(undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setVisible(false);
  };

  return (
    <div className={`modal ${visible ? "is-active" : ""}`}>
      <div className="modal-background" onClick={handleClose} />
      <div className="modal-content">
        {image?.thumbnail?.url ? (
          <div className="box">
            Current Thumbnail
            <div>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img alt="" src={image.thumbnail.url} />
            </div>
          </div>
        ) : null}
        <div className="box">
          <div>Upload new thumbnail image (png/jpg)</div>
          <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => setNewThumbnailImage(e.target.files?.[0])}
            />
          </div>
          {saving ? (
            <div style={{ marginTop: "20px" }}>
              <progress
                className="progress is-small is-primary"
                max="100"
              ></progress>
            </div>
          ) : (
            <div className="buttons is-right">
              <button
                className="button is-primary"
                onClick={() => handleConfirm()}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
      <button className="modal-close is-large" onClick={handleClose} />
    </div>
  );
};

export default UploadThumbnailModal;
