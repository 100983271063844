import React from "react";
import { NavBar } from "../Components/layouts/nav.bar";
import { Outlet } from "react-router-dom";
import { Layout as AntdLayout } from "antd";

const mainLayoutStyle = {
  background: "white",
};

const style = {
  padding: "40px",
  background: "white",
};

const stagingBannerStyle: any = {
  textAlign: "center",
  fontWeight: "bold",
  marginBottom: 0,
};

const isStaging = process.env.REACT_APP_ENV_BANNER === "staging";
const StagingBanner = () => (
  <div className="notification is-warning" style={stagingBannerStyle}>
    STAGING
  </div>
);

const Layout = () => {
  return (
    <>
      {isStaging && <StagingBanner />}
      <AntdLayout style={mainLayoutStyle}>
        <NavBar />
        <AntdLayout style={style}>
          <Outlet />
        </AntdLayout>
      </AntdLayout>
    </>
  );
};

export default Layout;
