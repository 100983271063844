import { formatDate } from "../../../Util";
import usePagination from "../../../utils/usePagination";
import useFetching from "../../../utils/useFetching";
import Table from "../../../Components/layouts/table";

type EventsTableProps = {
  lotId: string;
};

const columns = [
  { title: "Type", dataIndex: "type", key: "type" },
  { title: "At", dataIndex: "createdAt", key: "createdAt", render: formatDate },
  { title: "User", dataIndex: ["payload", "userId"], key: "user" },
];

const EventsTable = ({ lotId }: EventsTableProps) => {
  const { page, onChange } = usePagination();
  const { data, meta, isLoading } = useFetching(
    `/lots/${lotId}/events`,
    { page },
    [page],
  );

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      meta={meta}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default EventsTable;
