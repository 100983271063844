import React, { useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import * as backend from "../../Backend";
import "react-multi-email/dist/style.css";

const ReferralProgramMassEnable = ({ onClose }: any) => {
  const [emails, setEmails] = useState<Array<string>>([]);
  const [error, setError] = useState<string>("");

  const onSubmit = async () => {
    setError("");

    if (emails.length === 0) {
      setError("Please enter at least one e-mail address");
      return;
    }

    try {
      await backend.user.referralProgram.bulkCreate({ emails });
      onClose(true);
    } catch (e) {
      const error = e as any;
      setError(error.response?.data?.message);
    }
  };

  return (
    <div className="columns">
      <div className="column" />
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">
            <h3 className="title">Mass Enable Referral Program</h3>
          </div>
        </div>

        {error && <div className="notification is-danger">{error}</div>}

        <div className="field">
          <label htmlFor="email" className="label">
            User e-mail
          </label>
          <div className="control">
            <ReactMultiEmail
              placeholder="Enter a comma separated list of e-mails"
              emails={emails}
              onChange={(_emails) => {
                setEmails(_emails);
              }}
              validateEmail={(email) => isEmail(email)}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      x
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="buttons is-right">
          <button className="button is-danger is-light" onClick={onClose}>
            Cancel
          </button>
          <button
            onClick={onSubmit}
            className="button is-success"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
      <div className="column" />
    </div>
  );
};

export default ReferralProgramMassEnable;
