"use client";

import React from "react";
import AuctionRoomAlerts from "../../Components/AuctionRoomAlerts";

const AuctionRoomAlertsNextPage = () => {
  return <AuctionRoomAlerts />;
};

export default AuctionRoomAlertsNextPage;
