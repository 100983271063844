"use client";

import React from "react";
import MassNotificationsPage from "../../Components/Pages/MassNotificationsPage";

const MassNotificationsNextPage = () => {
  return <MassNotificationsPage />;
};

export default MassNotificationsNextPage;
