import { useState } from "react";
import useFetching from "../../utils/useFetching";

const useLotsTable = () => {
  const [page, setPage] = useState(1);
  const {
    data: lots,
    meta,
    isLoading,
  } = useFetching("/lots", { page }, [page]);
  const onChange = (page: number) => setPage(page);

  return {
    lots,
    meta,
    onChange,
    isLoading,
  };
};

export default useLotsTable;
