import React, { useState } from "react";
import * as backend from "../Backend";

export const ReferralCodeForm = ({ onClose }: any) => {
  const [form, setForm] = useState({
    code: "",
    active: false,
    auto_accept: true,
  });
  const [error, setError] = useState("");

  const onChange = (key: string, value: any) => {
    if (key === "code" && value) value = value.toUpperCase();
    setForm({
      ...form,
      [key]: value,
    });
  };

  const onSubmit = async () => {
    try {
      await backend.referralCodes.create({ referral_code: form });
      onClose(true);
    } catch (e: any) {
      setError(e.response?.data?.errors);
    }
  };

  return (
    <div className="columns">
      <div className="column" />
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">
            <h3 className="title">Create Referral Code</h3>
          </div>
        </div>

        {error && <div className="notification is-danger">{error}</div>}

        <div className="field">
          <label htmlFor="code" className="label">
            Code
          </label>
          <div className="control">
            <input
              onChange={(e) => onChange("code", e.target.value)}
              value={form.code}
              placeholder="code"
              id="code"
              className="input"
              type="text"
              name="code"
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="active" className="checkbox">
            <input
              onChange={(e) => onChange("active", e.target.checked)}
              id="active"
              name="active"
              type="checkbox"
              checked={form.active}
            />
            Active
          </label>
        </div>
        <div className="field">
          <label htmlFor="auto_accept" className="checkbox">
            <input
              onChange={(e) => onChange("auto_accept", e.target.checked)}
              id="auto_accept"
              name="auto_accept"
              type="checkbox"
              checked={form.auto_accept}
            />
            Auto-Accept
          </label>
        </div>
        <div className="buttons is-right">
          <button className="button is-danger is-light" onClick={onClose}>
            Cancel
          </button>
          <button
            onClick={onSubmit}
            className="button is-success"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
      <div className="column" />
    </div>
  );
};
