import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { LotsSection as Section, Media } from "../../../../../generated/types";

interface SectionItemProps {
  section: Section;
  reorderSections: boolean;
  sectionPositions: { [key: string]: string | number };
  setSectionPositions: (position: any) => void;
  onEditSection: (section: Section) => void;
  onDeleteSection: (section: Section) => void;
  isMoving: boolean;
}

const SectionItem: React.FC<SectionItemProps> = ({
  section,
  reorderSections,
  sectionPositions,
  setSectionPositions,
  onEditSection,
  onDeleteSection,
  isMoving,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: section.id,
    resizeObserverConfig: { disabled: false },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    backgroundColor: isDragging ? "#f9f9f9" : "transparent",
    border: isDragging ? "2px dashed #4A90E2" : "none",
  };

  const contentClass = isMoving ? "is-section-dragging" : "";

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={`section-item ${contentClass}`}
      >
        <div>
          <div>
            Position:
            {reorderSections ? (
              <input
                type="text"
                value={sectionPositions[section.id]}
                onChange={(e) => {
                  setSectionPositions({
                    ...sectionPositions,
                    [section.id]: e.target.value,
                  });
                }}
              />
            ) : (
              <span>{section.position}</span>
            )}
          </div>
          <div className="columns">
            <div className="column">
              <strong>{section.title}</strong>
            </div>
            <div className="column is-three-quarters">
              <div className="is-linewrap">{section.content}</div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <strong>Audio url</strong>
            </div>
            <div className="column is-three-quarters">
              <div className="is-linewrap">{section.audioUrl || "-"}</div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <strong>Show see more</strong>
            </div>
            <div className="column is-three-quarters">
              <div className="is-linewrap">
                {section.showSeeMore ? "YES" : "NO"}
              </div>
            </div>
          </div>
          <h3>Medias</h3>
          {section.medias.map((media: Media) => (
            <div key={`section-${media.id}`}>
              <div className="columns">
                <div className="column">
                  <strong>Type</strong>
                </div>
                <div className="column is-three-quarters">
                  <div className="is-linewrap">{media.type || "-"}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Title</strong>
                </div>
                <div className="column is-three-quarters">
                  <div className="is-linewrap">{media.title || "-"}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Description</strong>
                </div>
                <div className="column is-three-quarters">
                  <div className="is-linewrap">{media.description || "-"}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Url</strong>
                </div>
                <div className="column is-three-quarters">
                  <div className="is-linewrap">{media.url || "-"}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Thumbnail Url</strong>
                </div>
                <div className="column is-three-quarters">
                  <div className="is-linewrap">{media.thumbnailUrl || "-"}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>File</strong>
                </div>
                <div className="column is-three-quarters">
                  <div className="is-linewrap">{media.fileUrl || "-"}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Configuration</strong>
                </div>
                <div className="column is-three-quarters">
                  <div className="is-linewrap">
                    {media.configuration || "-"}
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
        <div className="buttons is-right">
          <button
            className="button is-warning"
            onClick={() => onEditSection(section)}
          >
            Edit
          </button>
          <button
            className="button is-danger"
            onClick={() => onDeleteSection(section)}
          >
            Delete
          </button>
        </div>
      </div>
      <hr />
    </>
  );
};

export default SectionItem;
