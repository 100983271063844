import React, { useState } from "react";
import * as backend from "../../Backend";
import { useForm } from "react-hook-form";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { useToast } from "../../providers/notification.provider";
import useFetching from "../../utils/useFetching";
import Table from "../layouts/table";

const columnsMassNotifications = [
  { title: "ID", key: "id", dataIndex: "id" },
  { title: "Type", key: "type", dataIndex: "type" },
  { title: "Sent At", key: "sentAt", dataIndex: "sentAt" },
  { title: "Total", key: "totalItems", dataIndex: "totalItems" },
  {
    title: "Pending",
    key: "totalPendingItems",
    dataIndex: "totalPendingItems",
  },
  { title: "Queued", key: "totalQueuedItems", dataIndex: "totalQueuedItems" },
  {
    title: "Delivered",
    key: "totalDeliveredItems",
    dataIndex: "totalDeliveredItems",
  },
  {
    title: "Undelivered",
    key: "totalUndeliveredItems",
    dataIndex: "totalUndeliveredItems",
  },
  { title: "Failed", key: "totalFailedItems", dataIndex: "totalFailedItems" },
  { title: "Sent", key: "totalSentItems", dataIndex: "totalSentItems" },
];

const columnsEvents = [
  { title: "At", key: "createdAt", dataIndex: "createdAt" },
  { title: "Type", key: "type", dataIndex: "type" },
  {
    title: "Payload",
    key: "payload",
    dataIndex: "payload",
    render: (payload: any) => JSON.stringify(payload),
  },
];

const MassNotificationsPage = () => {
  const [pageMass, setPageMass] = useState(1);
  const [pageEvents, setPageEvents] = useState(1);

  const {
    data: massNotifications,
    meta: massNotificationsMeta,
    isLoading: isMassNotificationsLoading,
  } = useFetching("/mass_notifications", { page: pageMass }, [pageMass]);
  const {
    data: events,
    meta: eventsMeta,
    isLoading: isEventsLoading,
  } = useFetching("/mass_notifications/events", { page: pageEvents }, [
    pageEvents,
  ]);

  const onChangeMassNotificationsPage = (page: number) => setPageMass(page);
  const onChangeEventsPage = (page: number) => setPageEvents(page);

  const { reset, register, handleSubmit, getValues } = useForm();
  const toast = useToast();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [showSmsForm, setShowSmsForm] = useState(false);
  const [showPushForm, setShowPushForm] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [showChannelField, setShowChannelField] = useState(false);
  const [emails, setEmails] = useState<Array<string>>([]);
  const [userNotFoundError, setUserNotFoundError] = useState<{
    defaultMessage: string;
  } | null>(null);
  const [forceReload, setForceReload] = useState(1);

  const onSubmit = () => {
    setShowConfirmation(true);
  };

  const resetStates = () => {
    setShowConfirmation(false);
    setDisableSend(false);
    setShowSmsForm(false);
    setShowPushForm(false);
    setShowEmailField(false);
    setShowChannelField(false);
    setEmails([]);
  };

  const send = () => {
    setDisableSend(true);
    const type = getValues("type");
    const channel = getValues("channel");
    backend.massNotifications
      .create(
        type,
        channel,
        getValues("message"),
        type === "USER_WITH_EMAIL" ? Array.from(new Set(emails)) : null,
        channel === "PUSH" ? getValues("title") : null,
      )
      .then(() => {
        toast({ type: "good", message: "Sent Mass Notification!" });
        resetStates();
        reset();
        setForceReload(forceReload + 1);
      })
      .catch((err) => {
        setShowConfirmation(false);
        setDisableSend(false);
        const message = err?.response?.data?.message || err;
        toast({ type: "bad", message: `Something went wrong: ${message}` });
      });
  };

  return (
    <>
      <div
        className={`notification is-danger is-medium ${userNotFoundError ? "is-visible" : "is-hidden"}`}
      >
        {userNotFoundError?.defaultMessage}
        <button
          className="delete"
          onClick={() => setUserNotFoundError(null)}
        ></button>
      </div>
      <div className={`modal ${showConfirmation ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowConfirmation(false)}
        />
        <div className="modal-content">
          <div className="box">
            <div>
              You are about to send {getValues("message")} to{" "}
              {getValues("type")}. Are you sure? This will send message by using
              the {getValues("channel")} channel to all the selected users.
            </div>
            <div className="buttons is-right">
              <button
                disabled={disableSend}
                className="button is-primary"
                onClick={send}
              >
                Send
              </button>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => setShowConfirmation(false)}
        />
      </div>
      <div className="columns">
        <div className="column" />
        <div className="column is-half">
          <div className="panel">
            <div className="panel-heading">Send Mass Notification</div>
            <div className="panel-block">
              <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <label htmlFor="type" className="label">
                      Type
                    </label>
                    <div className="select">
                      <select
                        {...register("type", { required: true })}
                        onChange={(e) => {
                          const shouldShowEmailField =
                            e.target.value === "USER_WITH_EMAIL";
                          setShowEmailField(shouldShowEmailField);
                          setShowChannelField(
                            !shouldShowEmailField && !!e.target.value,
                          );
                        }}
                      >
                        <option value="">Select one</option>
                        <option value="ACCEPTED_USERS">
                          All Accepted Users
                        </option>
                        <option value="USERS_WITH_BIDDING_ACCOUNT">
                          All Users with Bidding Account
                        </option>
                        <option value="USER_WITH_EMAIL">
                          To specific users
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="field" hidden={!showEmailField}>
                    <label htmlFor="email" className="label">
                      User e-mail
                    </label>
                    <div className="control">
                      {/* <input className="input" type="mail" name="email" ref={register} required={showEmailField} onChange={e => setShowChannelField(!!e.target.value)}/> */}
                      <ReactMultiEmail
                        placeholder="Enter a comma separated list of e-mails"
                        emails={emails}
                        onChange={(_emails) => {
                          setEmails(_emails);
                          setShowChannelField(!!_emails.length);
                        }}
                        validateEmail={(email) => isEmail(email)}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                x
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="field" hidden={!showChannelField}>
                    <label htmlFor="channel" className="label">
                      Channel
                    </label>
                    <div className="select">
                      <select
                        {...register("channel", { required: true })}
                        onChange={(e) => {
                          setShowSmsForm(e.target.value === "SMS");
                          setShowPushForm(e.target.value === "PUSH");
                        }}
                      >
                        <option value="">Select one</option>
                        <option value="SMS">SMS</option>
                        <option value="PUSH">Push notification</option>
                      </select>
                    </div>
                  </div>

                  <div
                    className="field"
                    hidden={!showChannelField || !showPushForm}
                  >
                    <label htmlFor="title" className="label">
                      Title
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        {...register("title", { required: showPushForm })}
                      />
                    </div>
                  </div>

                  <div
                    className="field"
                    hidden={
                      !showChannelField || (!showPushForm && !showSmsForm)
                    }
                  >
                    <label htmlFor="message" className="label">
                      Message
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        {...register("message", { required: true })}
                      />
                    </div>
                  </div>

                  <button
                    className="button is-success"
                    type="submit"
                    disabled={
                      !showChannelField || (!showSmsForm && !showPushForm)
                    }
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="column" />
      </div>
      <Table
        dataSource={massNotifications}
        columns={columnsMassNotifications}
        rowKey="id"
        meta={massNotificationsMeta}
        onChange={onChangeMassNotificationsPage}
        loading={isMassNotificationsLoading}
      />

      <Table
        dataSource={events}
        columns={columnsEvents}
        rowKey="id"
        meta={eventsMeta}
        onChange={onChangeEventsPage}
        loading={isEventsLoading}
      />
    </>
  );
};

export default MassNotificationsPage;
