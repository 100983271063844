import React, { useState } from "react";
import { Modal, Image, Carousel } from "antd";

type ImageGalleryProps = {
  images: string[];
  altText?: string;
};

const ImageGallery = ({
  images,
  altText = "Gallery Image",
}: ImageGalleryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const openModal = (index: number) => {
    setCurrentSlide(index);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div style={{ display: "flex", gap: "8px", cursor: "pointer" }}>
        {images.slice(0, 3).map((src, index) => (
          <Image
            key={index}
            src={src}
            alt={`Preview ${index}`}
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
            preview={false}
            onClick={() => openModal(index)}
          />
        ))}
        <div onClick={() => openModal(0)}>
          {images.length > 3 && <span>+{images.length - 3} more</span>}
        </div>
      </div>

      <Modal
        title="Image Gallery"
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <Carousel
          initialSlide={currentSlide}
          afterChange={(current) => setCurrentSlide(current)}
          style={{ textAlign: "center" }}
          dotPosition="bottom"
          swipeToSlide
          draggable
        >
          {images.map((img, index) => (
            <div key={index}>
              <Image
                src={img}
                alt={`${altText} ${index + 1}`}
                style={{
                  maxHeight: "400px",
                  margin: "0 auto",
                  objectFit: "contain",
                }}
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    </>
  );
};

export default ImageGallery;
