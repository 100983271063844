"use client";

import { useContext } from "react";
import { UserContext } from "./user_provider";

export const AuthenticatedProvider = ({ children }: any) => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return null;
  }

  return user ? children : null;
};

export const UnAuthenticatedProvider = ({ children }: any) => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return null;
  }

  return user ? null : children;
};
