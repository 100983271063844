import { useCallback, useState } from "react";

const usePagination = () => {
  const [page, setPage] = useState(1);
  const onChange = useCallback((page: number) => setPage(page), []);

  return {
    page,
    onChange,
  };
};

export default usePagination;
