"use client";

import React from "react";
import EventsPage from "../../Components/Pages/EventsPage";

const EventsNextPage = () => {
  return <EventsPage />;
};

export default EventsNextPage;
