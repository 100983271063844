import usePagination from "../../../utils/usePagination";
import useFetching from "../../../utils/useFetching";
import Table from "../../../Components/layouts/table";
import { LotsRsvp } from "../../../generated/types";
import { Link } from "../../../utils/navigationUtils";

type PaddlesTableProps = {
  lotId: string;
};

const columns = [
  { title: "Email", dataIndex: ["user", "email"], key: "email" },
  { title: "First Name", dataIndex: ["user", "firstName"], key: "firstName" },
  { title: "Last Name", dataIndex: ["user", "lastName"], key: "lastName" },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    render: (_: any, rsvp: LotsRsvp) => (
      <Link
        to={`/users/${rsvp.user?.id}`}
        className="button is-small is-primary"
      >
        View
      </Link>
    ),
  },
];

const PaddlesTable = ({ lotId }: PaddlesTableProps) => {
  const { page, onChange } = usePagination();
  const { data, meta, isLoading } = useFetching(
    `/lots/${lotId}/rsvps`,
    { page },
    [page],
  );

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      meta={meta}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default PaddlesTable;
