import { formatDateTime } from "../../Util";
import React from "react";
import { User } from "../../generated/types";

type UserProfileProps = {
  user: User;
  editUserInfo: boolean;
  setUserInfoUpdateRequest: (data: any) => void;
  userInfoUpdateRequest: any;
};

const UserProfile = ({
  user,
  editUserInfo,
  setUserInfoUpdateRequest,
  userInfoUpdateRequest,
}: UserProfileProps) => {
  return (
    <div className="box">
      <h2 className="subtitle">Profile</h2>
      {user.profile ? (
        <>
          <div className="columns">
            <div className="column">
              <strong>Phone #</strong>
            </div>
            <div className="column">
              {editUserInfo ? (
                <input
                  className="input"
                  type="tel"
                  placeholder={user.profile.phoneNumber}
                  onChange={(e) =>
                    setUserInfoUpdateRequest({
                      ...userInfoUpdateRequest,
                      profile_attributes: {
                        phoneNumber: e.target.value || undefined,
                      },
                    })
                  }
                />
              ) : (
                user.profile.phoneNumber
              )}
            </div>
            <div className="column">
              <strong>Address</strong>
            </div>
            <div className="column">{user.profile.address}</div>
          </div>
          <div className="columns">
            <div className="column">
              <strong>Referral</strong>
            </div>
            <div className="column">{user.referralCode?.code || "-"}</div>
            <div className="column">
              <strong>Accepted At</strong>
            </div>
            <div className="column">
              {formatDateTime(user.profile.acceptedAt as unknown as Date)}
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <strong>Road Rules Accepted At</strong>
            </div>
            <div className="column">
              {formatDateTime(
                user.profile.roadRulesAcceptedAt as unknown as Date,
              )}
            </div>
            <div className="column" />
            <div className="column" />
          </div>
        </>
      ) : (
        <div>No profile</div>
      )}
    </div>
  );
};

export default UserProfile;
