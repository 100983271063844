import React, { useState } from "react";
import { ParkingLotForm } from "../ParkingLotForm";
import { useToast } from "../../providers/notification.provider";
import { LotsParking } from "../../generated/types";
import Table from "../layouts/table";
import useFetching from "../../utils/useFetching";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { del, post, put } from "../../utils/http";

const ParkingLotsPage = () => {
  const [page, setPage] = useState(1);
  const {
    data: parkingLots,
    meta,
    onLoadData,
  } = useFetching<LotsParking[]>("/lots/parkings_v2", { page }, [page]);
  const toast = useToast();
  const [showForm, setShowForm] = useState(false);
  const [currentParkingLot, setCurrentParkingLot] = useState<any>(null);
  const onChange = (page: number) => setPage(page);

  const onShowForm = () => {
    setCurrentParkingLot(null);
    setShowForm(true);
  };

  const onClose = (forceReload = false) => {
    setShowForm(false);

    if (forceReload) {
      onLoadData();
    }
  };

  const forceRefreshParkingLot = async () => {
    await post("/lots/parkings_v2/force_refresh");
    toast({ type: "good", message: "Force Refresh done" });
  };

  const update = async (parking: any) => {
    await put(`/lots/parkings_v2/${parking.id}/enable`);
    toast({ type: "good", message: "Updated" });
    onLoadData();
  };

  const edit = async (parking: any) => {
    setCurrentParkingLot(parking);
    setShowForm(true);
  };

  const destroy = async (parking: any) => {
    del(`/lots/parkings_v2/${parking.id}`);
    toast({ type: "good", message: "Deleted" });
    onLoadData();
  };

  const ActionCell = (_: any, record: LotsParking) => {
    const onUpdate = () => update(record);
    const onEdit = () => edit(record);
    const onDelete = () => destroy(record);

    return (
      <>
        <span className="button is-small is-primary" onClick={onUpdate}>
          Change Enable
        </span>
        &nbsp;
        <span className="button is-small is-warning" onClick={onEdit}>
          Edit
        </span>
        &nbsp;
        <span className="button is-small is-danger" onClick={onDelete}>
          Delete
        </span>
      </>
    );
  };

  const columns = [
    { title: "ID", key: "id", dataIndex: "id" },
    { title: "Version", key: "version", dataIndex: "version" },
    { title: "url", key: "url", dataIndex: "url" },
    {
      title: "Enabled",
      key: "enabled",
      dataIndex: "enabled",
      render: (enabled: boolean) =>
        enabled ? <CheckOutlined /> : <CloseOutlined />,
    },
    {
      title: "",
      key: "actions",
      dataIndex: "actions",
      render: ActionCell,
    },
  ];

  if (showForm) {
    return <ParkingLotForm parkingLot={currentParkingLot} onClose={onClose} />;
  }

  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <button
                className="button is-primary"
                style={{ marginRight: "8px" }}
                onClick={onShowForm}
              >
                New Parking Lot
              </button>
              <button
                className="button is-primary"
                onClick={forceRefreshParkingLot}
              >
                Force Refresh
              </button>
            </ul>
          </nav>
        </div>
      </div>
      <Table
        dataSource={parkingLots}
        columns={columns}
        rowKey="id"
        meta={meta}
        onChange={onChange}
      />
    </div>
  );
};

export default ParkingLotsPage;
