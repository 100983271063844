import useDataLoader from "../../../utils/useDataLoader";
import { User, Event } from "../../../generated/types";
import Table from "../../../Components/layouts/table";
import { formatDateTime } from "../../../Util";
import { useMemo, useState } from "react";

type EventsTableProps = {
  user: User;
};

const columns = [
  {
    title: "At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value: any) => formatDateTime(value),
  },
  { title: "Type", key: "type", dataIndex: "type" },
  {
    title: "Payload",
    key: "payload",
    dataIndex: "payload",
    render: (payload: any) => JSON.stringify(payload),
  },
];

const baseParams = {
  order: "created_at",
  direction: "desc",
};

const EventsTable = ({ user }: EventsTableProps) => {
  const [page, setPage] = useState(1);
  const params = useMemo(() => ({ ...baseParams, page }), [page]);
  const { data, meta } = useDataLoader<Event[]>({
    path: `/users/${user.id}/events`,
    params,
  });
  const onChange = (newPage: number) => setPage(newPage);

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      meta={meta}
      onChange={onChange}
    />
  );
};

export default EventsTable;
