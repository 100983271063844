"use client";

import React from "react";
import { SignInPage } from "../../../Components/Pages/SignInPage";

const SignInNextPage = () => {
  return <SignInPage />;
};

export default SignInNextPage;
