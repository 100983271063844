import { useState } from "react";
import { Lot } from "../../../../generated/types";
import * as backend from "../../../../Backend";

const useMediasSection = (lot: Lot, loadLot: () => void) => {
  const [showMedias, setShowMedias] = useState(false);
  const [showNewMedia, setShowNewMedia] = useState(false);
  const [currentMedia, setCurrentMedia] = useState<any>(null);

  const onAddMedia = (media: any) => {
    setCurrentMedia(media || {});
    setShowNewMedia(true);
  };

  const onDeleteMedia = (media: any) => {
    const body = {
      lot: {
        mediasAttributes: [
          {
            id: media.id,
            _destroy: 1,
          },
        ],
      },
    };

    backend.lot.updateMultipartLot(lot.id, body).then(() => {
      setCurrentMedia(null);
      setShowNewMedia(false);
      loadLot();
    });
  };

  const onSubmitMedia = (media: any) => {
    backend.lot
      .updateMultipartLot(lot.id, {
        lot: {
          mediasAttributes: [media],
        },
      })
      .then(() => {
        setCurrentMedia(null);
        setShowNewMedia(false);
        loadLot();
      });
  };

  return {
    showMedias,
    setShowMedias,
    showNewMedia,
    setShowNewMedia,
    onAddMedia,
    onDeleteMedia,
    onSubmitMedia,
    currentMedia,
  };
};

export default useMediasSection;
