import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Lot } from "../generated/types";
import * as backend from "../Backend";
import moment, { Moment } from "moment";
import { convertToMomentWithTimezone, getTimezone } from "../Util";
import "react-datepicker/dist/react-datepicker.css";
import { useToast } from "../providers/notification.provider";

const DatePickerReact: any = DatePicker as any;

type IProps = {
  lot?: Lot;
  onSuccess: (l: Lot) => void;
  onCancel: () => void;
};

const LotForm: FC<IProps> = ({ lot = null, onSuccess, onCancel }) => {
  let defaultValues: any = {};
  if (lot) {
    defaultValues = {
      title: lot.title,
      artistName: lot.artistName,
      auctionMobilityId: lot.auctionMobilityId,
      yearOfCreation: lot.yearOfCreation,
      estimate: lot.estimate,
      conditionReport: lot.conditionReport,
      detail: lot.detail,
      viewingLocation: lot.viewingLocation,
      personLocationUrl: lot.personLocationUrl,
      virtualLocationUrl: lot.virtualLocationUrl,
      hideWhitespaceCarousel: lot.hideWhitespaceCarousel,
      hideShareLink: lot.hideShareLink,
      hideScreenshotToast: lot.hideScreenshotToast,
      hideSchedulingView: lot.hideSchedulingView,
    };
  }
  const toast = useToast();
  const [errors, setErrors] = useState<string[]>([]);
  const { register, handleSubmit, setValue } = useForm({ defaultValues });
  const [hideWhitespaceComparables, setHideWhitespaceComparables] = useState<
    boolean[]
  >(lot ? lot.hideWhitespace.comparables : [false, false]);
  const [hideWhitespaceSections, setHideWhitespaceSections] = useState<
    boolean[]
  >(lot ? lot.hideWhitespace.sections : []);

  const [lotDate, setLotDate] = useState<Moment | null>(
    convertToMomentWithTimezone(lot && lot.auctionStartsAt, getTimezone()),
  );
  const [saving, setSaving] = useState(false);

  const addHideWhitespaceSection = () => {
    setHideWhitespaceSections([...hideWhitespaceSections, false]);
  };

  useEffect(() => {
    if (lot) {
      const initialDate = convertToMomentWithTimezone(
        lot.auctionStartsAt,
        getTimezone(),
      );
      setValue("auctionStartsAtHour", initialDate.hour());
      setValue("auctionStartsAtMinute", initialDate.minute());
    } else {
      setValue("auctionStartsAtHour", 0);
      setValue("auctionStartsAtMinute", 0);
    }
  }, []) // eslint-disable-line

  const onSubmit = async (data: any) => {
    setErrors([]);

    if (lotDate) {
      lotDate
        .set("hour", data.auctionStartsAtHour)
        .set("minute", data.auctionStartsAtMinute);
      delete data.auctionStartsAtHour;
      delete data.auctionStartsAtMinute;
    } else {
      setErrors(["Auction Starts AT is invalid"]);
      return;
    }

    setSaving(true);

    let result;

    try {
      if (lot) {
        result = await backend.lot.update(lot.id, {
          ...data,
          auctionStartsAt: convertToMomentWithTimezone(
            lotDate.toDate(),
            "UTC",
          ).format(),
          hideWhitespace: {
            comparables: hideWhitespaceComparables,
            sections: hideWhitespaceSections,
          },
        });
      } else {
        result = await backend.lot.create({
          ...data,
          auctionStartsAt: convertToMomentWithTimezone(
            lotDate.toDate(),
            "UTC",
          ).format(),
          hideWhitespace: {
            comparables: hideWhitespaceComparables,
            sections: hideWhitespaceSections,
          },
        });
      }

      if (data.withTestData) {
        const masterImage = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/master.png",
        );
        await backend.lot.replaceMasterImage(
          result.id,
          new File([masterImage], "master.png"),
          { width: "823", height: "1234" },
        );

        const fullScreenImage = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/fullScreen.png",
        );
        await backend.lot.replaceFullScreenImage(
          result.id,
          new File([fullScreenImage], "fullScreen.png"),
          { width: "675", height: "1200" },
        );

        const image1 = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/image-1.png",
        );
        await backend.lot.addImage(
          result.id,
          new File([image1], "image-1.png"),
          { width: "932", height: "1200" },
        );

        const image2 = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/image-2.png",
        );
        await backend.lot.addImage(
          result.id,
          new File([image2], "image-2.png"),
          { width: "1761", height: "2640" },
        );

        const image3 = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/image-3.png",
        );
        await backend.lot.addImage(
          result.id,
          new File([image3], "image-3.png"),
          { width: "1761", height: "2640" },
        );

        const comp1 = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/comp-1.jpg",
        );
        await backend.lot.addComparable(
          result.id,
          new File([comp1], "comp-1.jpg"),
          {
            width: "2000",
            height: "1500",
            description:
              "Installation view, Laura Owens, Whitney Museum of American Art, New York, November 2018 - February 2018. Photograph by Ron Amstutz, Artwork: © 2020 Laura Owens.",
          },
        );

        const comp2 = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/comp-2.jpg",
        );
        await backend.lot.addComparable(
          result.id,
          new File([comp2], "comp-2.jpg"),
          {
            width: "1010",
            height: "2000",
            description:
              "Marcel Duchamp, Bicycle Wheel, 1951. Museum of Modern Art, New York. © 2020 Artists Rights Society (ARS), New York / ADAGP, Paris / Estate of Marcel Duchamp.",
          },
        );

        const comp3 = await backend.testing.getImage(
          "https://storage.googleapis.com/fw-staging-testing/comp-3.jpg",
        );
        await backend.lot.addComparable(
          result.id,
          new File([comp3], "comp-3.jpg"),
          {
            width: "560",
            height: "642",
            description:
              "Kurt Schwitters, Construction of Four Nobel Ladies, 1919. Los Angeles County Museum of Art. © Artists Rights Society (ARS), New York / VG Bild-Kunst, Bonn.",
          },
        );

        await backend.lot.createSection(result.id, {
          title: "Provenance",
          content:
            "Provenance - Lorem ipsum dolor sit amet, case pericula in usu. No sit ipsum audire. Option euismod constituto eam cu, ad eripuit eligendi scaevola pro. An pri doming singulis definitionem. Te bonorum propriae vix, mel odio nulla omnes et. Per ubique equidem corpora no, usu cu idque everti eloquentiam.",
          taggedContent:
            "Provenance - Lorem ipsum dolor sit amet, case pericula in usu. No sit ipsum audire. Option euismod constituto eam cu, ad eripuit eligendi scaevola pro. An pri doming singulis definitionem. Te bonorum propriae vix, mel odio nulla omnes et. Per ubique equidem corpora no, usu cu idque everti eloquentiam.",
        });

        await backend.lot.createSection(result.id, {
          title: "Exhibited",
          content:
            "Exhibited - Lorem ipsum dolor sit amet, case pericula in usu. No sit ipsum audire. Option euismod constituto eam cu, ad eripuit eligendi scaevola pro. An pri doming singulis definitionem. Te bonorum propriae vix, mel odio nulla omnes et. Per ubique equidem corpora no, usu cu idque everti eloquentiam.",
          taggedContent:
            "Exhibited - Lorem ipsum dolor sit amet, case pericula in usu. No sit ipsum audire. Option euismod constituto eam cu, ad eripuit eligendi scaevola pro. An pri doming singulis definitionem. Te bonorum propriae vix, mel odio nulla omnes et. Per ubique equidem corpora no, usu cu idque everti eloquentiam.",
        });
      }

      onSuccess(result);
      toast({ type: "good", message: "Lot saved successfully" });
    } catch (err) {
      const newErrors = (err as any)?.response?.data?.error;

      if (typeof newErrors === "string") {
        setErrors([newErrors]);
        toast({ type: "bad", message: newErrors || "Something went wrong." });
      } else {
        setErrors(newErrors || ["Something went wrong"]);
        toast({
          type: "bad",
          message: newErrors?.[0] || "Something went wrong",
        });
      }

      setSaving(false);
    }
  };

  function field(
    label: string,
    name: string,
    type: string = "text",
    required = false,
  ) {
    return (
      <div className="field">
        {type === "checkbox" ? (
          <label htmlFor={name} className="checkbox">
            <input id={name} {...register(name)} type="checkbox" />
            {label} {required && <span style={{ color: "#ff0000b3" }}>*</span>}
          </label>
        ) : (
          <>
            <label htmlFor={name} className="label">
              {label}{" "}
              {required && <span style={{ color: "#ff0000b3" }}>*</span>}
            </label>
            <div className="control">
              {type === "textarea" ? (
                <textarea
                  id={name}
                  className="textarea"
                  {...register(name, { required: required })}
                />
              ) : (
                <input
                  id={name}
                  className="input"
                  type={type}
                  {...register(name, { required: required })}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  function populateTestData(e: any) {
    e.preventDefault();

    const random = Math.floor(Math.random() * 100000);
    setValue("title", `Test Lot #${random}`);
    setValue("artistName", `Artist #${random}`);
    setValue("auctionMobilityId", `AM-${random}`);
    setValue("yearOfCreation", random);
    setValue("viewingLocation", "Palo Alto, CA");
    setValue("personLocationUrl", "www.google.com");
    setValue("virtualLocationUrl", "www.google.com");
    setValue("estimate", `Estimate ${random} - ${random + 100}`);
    setValue(
      "conditionReport",
      "Condition Report -- Lorem ipsum dolor sit amet, case pericula in usu. No sit ipsum audire. Option euismod constituto eam cu, ad eripuit eligendi scaevola pro. An pri doming singulis definitionem. Te bonorum propriae vix, mel odio nulla omnes et. Per ubique equidem corpora no, usu cu idque everti eloquentiam.",
    );
    setValue(
      "detail",
      "Detail -- Lorem ipsum dolor sit amet, case pericula in usu. No sit ipsum audire. Option euismod constituto eam cu, ad eripuit eligendi scaevola pro. An pri doming singulis definitionem. Te bonorum propriae vix, mel odio nulla omnes et. Per ubique equidem corpora no, usu cu idque everti eloquentiam.",
    );
    setValue("withTestData", true);
  }

  return (
    <>
      <div className="columns">
        <div className="column" />
        <div className="column is-half">
          <div className="panel">
            <div className="panel-heading">
              {lot ? `Edit Lot: ${lot.id}` : "New Lot"}
            </div>
            <div className="panel-block">
              <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {process.env.REACT_APP_TESTING_TOOLS === "true" ? (
                    <button
                      className="button is-warning"
                      onClick={(e) => populateTestData(e)}
                    >
                      Fill Test Data
                    </button>
                  ) : null}
                  {field("Title", "title", "textarea", true)}
                  <div>
                    <strong>
                      Starts At (24hr {getTimezone()})
                      <text style={{ color: "#ff0000b3" }}>*</text>{" "}
                    </strong>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <div className="control is-expanded">
                          <DatePickerReact
                            className="input"
                            selected={lotDate?.toDate()}
                            onChange={(date: any) => setLotDate(moment(date))}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control is-expanded">
                          <input
                            id="auctionStartsAtHour"
                            className="input"
                            type="number"
                            min="0"
                            max="23"
                            {...register("auctionStartsAtHour")}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control is-expanded">
                          <input
                            id="auctionStartsAtMinute"
                            className="input"
                            type="number"
                            min="0"
                            max="59"
                            {...register("auctionStartsAtMinute")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {field("Artist name", "artistName", "text", true)}
                  {field(
                    "Auction Mobility ID",
                    "auctionMobilityId",
                    "text",
                    true,
                  )}
                  {field("Viewing Location", "viewingLocation")}
                  {field("In person link", "personLocationUrl")}
                  {field("Virtual link", "virtualLocationUrl")}
                  {field("Year of Creation", "yearOfCreation", "textarea")}
                  {field("Estimate", "estimate", "textarea")}
                  {field("Detail", "detail", "textarea")}
                  {field("Condition Report", "conditionReport", "textarea")}
                  {field("Hide Share link", "hideShareLink", "checkbox")}
                  {field(
                    "Hide Screenshot toast",
                    "hideScreenshotToast",
                    "checkbox",
                  )}
                  {field(
                    "Hide Scheduling View",
                    "hideSchedulingView",
                    "checkbox",
                  )}

                  <hr />

                  {/* <h2>Hide white space section</h2>
                  {hideWhitespaceSections.map((hide, i) => (
                    <div key={`hidewhitespace-section-${i}`}>
                      Row {i + 1}&nbsp;
                      <input
                        type="checkbox"
                        checked={hide}
                        onChange={(e) => {
                          const newHideWhitespaceSection = [
                            ...hideWhitespaceSections,
                          ];
                          newHideWhitespaceSection[i] = e.target.checked;
                          setHideWhitespaceSections(newHideWhitespaceSection);
                        }}
                      />
                      <span
                        onClick={() => {
                          const newHideWhitespaceSections = [
                            ...hideWhitespaceSections,
                          ];
                          newHideWhitespaceSections.splice(i, 1);
                          setHideWhitespaceSections(newHideWhitespaceSections);
                        }}
                        style={{ cursor: "pointer", fontSize: "12px" }}
                      >
                        Remove
                      </span>
                    </div>
                  ))} */}

                  {/* <span
                    className="button is-primary is-light"
                    onClick={addHideWhitespaceSection}
                  >
                    Add new row
                  </span> */}

                  {process.env.REACT_APP_TESTING_TOOLS === "true" && (
                    <div>
                      {field(
                        "Generate Test Sections/Images/Etc.?",
                        "withTestData",
                        "checkbox",
                      )}
                    </div>
                  )}

                  {errors.map((e: any) => (
                    <p key={e} className="help is-danger">
                      {e}
                    </p>
                  ))}

                  {saving ? (
                    <div>
                      <progress
                        className="progress is-small is-primary"
                        max="100"
                      >
                        15%
                      </progress>
                    </div>
                  ) : (
                    <div className="buttons is-right">
                      <button
                        className="button is-danger is-light"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                      <button className="button is-success" type="submit">
                        Save
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="column" />
      </div>
    </>
  );
};

export default LotForm;
