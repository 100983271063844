"use client";

import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/user_provider";
import { useLocation, useNavigate } from "../../utils/navigationUtils";
import * as backend from "../../Backend";
import { signOut } from "../../firebase/auth/signInOut";
import { Layout as AntdLayout, Menu, MenuProps } from "antd";
import {
  CalendarOutlined,
  CodeOutlined,
  HeartOutlined,
  LogoutOutlined,
  UserOutlined,
  UnlockOutlined,
  CommentOutlined,
  AlertOutlined,
  CheckOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import useIsMobile from "../../utils/useIsMobile";

const items = [
  {
    label: "Users",
    key: "users",
    icon: <UserOutlined />,
  },
  {
    label: "Lots",
    key: "lots",
    icon: <HeartOutlined />,
  },
  {
    label: "Parking Lots",
    key: "parking-lots",
    icon: <CodeOutlined />,
  },
  {
    label: "Invite Codes",
    key: "invite-codes",
    icon: <CommentOutlined />,
  },
  {
    label: "Referral Codes",
    key: "referral-codes",
    icon: <CodeOutlined />,
  },
  {
    label: "Auction Room",
    key: "auction-rooms",
    icon: <UnlockOutlined />,
  },

  {
    label: "Auction Alerts",
    key: "auction-rooms-alerts",
    icon: <AlertOutlined />,
  },
  {
    label: "Mass Notifications",
    key: "mass-notifications",
    icon: <NotificationOutlined />,
  },
  {
    label: "Features",
    key: "features",
    icon: <CheckOutlined />,
  },

  {
    label: "Events",
    key: "events",
    icon: <CalendarOutlined />,
  },

  {
    label: "Sign Out",
    key: "sign-out",
    icon: <LogoutOutlined />,
  },
];

let bannerColor: string;
if (process.env.REACT_APP_ENV_BANNER === "production") {
  bannerColor = "is-danger";
} else if (process.env.REACT_APP_ENV_BANNER === "staging") {
  bannerColor = "is-warning";
} else {
  bannerColor = "is-success";
}

const menuStyle = { height: "100%" };
const { Sider } = AntdLayout;
const siderStyle: any = {
  top: 0,
  left: 0,
  minHeight: "100vh",
};

export const NavBar = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const [collapsed, setCollapsed] = useState(false);
  const current = location.pathname.match(/^\/([^/?]+)/)?.[1];
  const { user, setUser } = useContext(UserContext);
  const shouldDisplayNavBar = current !== "vip";
  const navigate = useNavigate();
  const onToggleCollapsed = () => setCollapsed(!collapsed);

  const onSignOut = async () => {
    await backend.user.signOut();
    localStorage.removeItem("mfaToken");
    await signOut();
    setUser(null);
    return navigate("/auth/sign-in");
  };

  const onClicks: any = {
    "sign-out": onSignOut,
  };

  const onClick: MenuProps["onClick"] = async (e) => {
    onClicks[e.key] ? onClicks[e.key]() : navigate(`/${e.key}`);
  };

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      width="200px"
      style={siderStyle}
      onCollapse={onToggleCollapsed}
    >
      <div
        className="navbar-item is-uppercase has-text-weight-bold"
        style={{ background: "black", color: "white" }}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src="/fairwarning.png" alt="logo" />
      </div>
      <div className="navbar-item is-uppercase" style={{ background: "black" }}>
        <p className={`help has-text-weight-bold is-light ${bannerColor}`}>
          {process.env.REACT_APP_ENV_BANNER || "development"} | v
          {process.env.REACT_APP_VERSION || "1.0.0"}
        </p>
      </div>
      {user && shouldDisplayNavBar && (
        <>
          <Menu
            style={menuStyle}
            onClick={onClick}
            selectedKeys={[current!]}
            mode="inline"
            items={items}
          />
        </>
      )}
    </Sider>
  );
};
