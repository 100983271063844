"use client";

import React, { useEffect, useState } from "react";
import { createContext } from "react";
import * as backend from "../Backend";
import { User } from "../generated/types";

type UserContextType = {
  user: User | null;
  loading: boolean;
  setUser: (user: User | null) => void;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  loading: true,
  setUser: () => {},
});

export const configureBackend = (token: string) => {
  backend.configure(token);
};

export async function checkAdmin(user: User): Promise<boolean> {
  let admin: any = false;
  if (user) {
    try {
      const res = await backend.user.me();
      admin = res.admin;
    } catch {
      admin = false;
    }
  }

  return admin;
}

export const UserProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("mfaToken");
      if (token) {
        configureBackend(token);

        try {
          const user = await backend.user.me();
          setUser(user);
          const admin = await checkAdmin(user);

          if (!admin) {
            setUser(null);
            localStorage.removeItem("mfaToken");
          }
        } catch {
          setUser(null);
          localStorage.removeItem("mfaToken");
        }
      }

      setLoading(false);
    })();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
