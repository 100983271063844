import usePagination from "../../../utils/usePagination";
import useFetching from "../../../utils/useFetching";
import { formatDate } from "../../../Util";
import Table from "../../../Components/layouts/table";

type AuctionRegistrationsTableProps = {
  lotId: string;
};

const columns = [
  {
    title: "Registration Auction Mobility ID",
    dataIndex: "auctionMobilityId",
    key: "auctionMobilityId",
  },
  { title: "Account", dataIndex: ["account", "name"], key: "accountName" },
  { title: "Paddle", dataIndex: "paddle", key: "paddle" },
  {
    title: "Account Auction Mobility Email",
    dataIndex: ["account", "auctionMobilityEmail"],
    key: "auctionMobilityEmail",
  },
  {
    title: "Account Auction Mobility ID",
    dataIndex: ["account", "auctionMobilityId"],
    key: "accountAuctionMobilityId",
  },
  { title: "At", dataIndex: "createdAt", key: "createdAt", render: formatDate },
];

const AuctionRegistrationsTable = ({
  lotId,
}: AuctionRegistrationsTableProps) => {
  const { page, onChange } = usePagination();
  const { data, meta, isLoading } = useFetching(
    `/lots/${lotId}/registrations`,
    { page },
    [page],
  );

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      meta={meta}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default AuctionRegistrationsTable;
