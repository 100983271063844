import React, { useState } from "react";
import { useToast } from "../providers/notification.provider";

interface InlineEditProps {
  name: string;
  initialValue: string | boolean;
  inputType?: "text" | "checkbox" | "textarea";
  label?: string;
  onSubmit: (data: { name: string; value: string | boolean }) => void;
  afterSubmit?: () => void;
  onChange?: (value: any) => any;
}

const InlineEdit = ({
  name,
  initialValue,
  inputType = "text",
  label,
  onSubmit,
  afterSubmit,
  ...props
}: InlineEditProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [originalValue, setOriginalValue] = useState(initialValue);
  const toast = useToast();

  const handleEditClick = () => setIsEditing(true);

  const handleCancelClick = () => {
    setIsEditing(false);
    setValue(initialValue);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    try {
      await onSubmit({ name, value });
      setOriginalValue(value);
    } catch (e: any) {
      setValue(originalValue);
      toast({
        type: "error",
        message: `Error updating lot - ${e?.response?.data?.message || "N/A"}`,
      });
    } finally {
      afterSubmit && afterSubmit();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue =
      inputType === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : e.target.value;

    if (props.onChange) {
      newValue = props.onChange(newValue);
    }

    setValue(newValue);

    if (inputType === "checkbox") {
      onSubmit({ name, value: newValue });
    }
  };

  return (
    <div
      className="field is-flex is-align-items-center"
      style={{ gap: "0.5rem" }}
    >
      {label && (
        <label
          className=""
          style={{ marginRight: "0.5rem", fontWeight: "bold" }}
        >
          {label}:
        </label>
      )}
      {inputType === "checkbox" ? (
        <div
          className="control is-flex is-align-items-center"
          style={{ gap: "0.25rem" }}
        >
          <input
            type="checkbox"
            name={name}
            checked={value as boolean}
            onChange={handleChange}
            style={{ marginRight: "0.5rem" }}
          />
        </div>
      ) : isEditing ? (
        <div
          className="is-flex is-align-items-center"
          style={{ gap: "0.25rem" }}
        >
          {inputType === "textarea" ? (
            <textarea
              className="textarea"
              name={name}
              value={value as string}
              onChange={handleChange}
            />
          ) : (
            <input
              className="input is-small"
              type="text"
              name={name}
              value={value as string}
              onChange={handleChange}
              style={{ maxWidth: "200px" }}
            />
          )}
          <button
            className="button is-success is-small"
            onClick={handleSaveClick}
            title="Save"
            style={{ padding: "8px" }}
          >
            <span className="icon is-small">
              <i className="fas fa-save"></i>
            </span>
          </button>
          <button
            className="button is-danger is-small"
            onClick={handleCancelClick}
            title="Cancel"
            style={{ padding: "8px" }}
          >
            <span className="icon is-small">
              <i className="fas fa-times"></i>
            </span>
          </button>
        </div>
      ) : (
        <div
          className="is-flex is-align-items-center"
          style={{ gap: "0.25rem" }}
        >
          <p
            style={{ marginBottom: 0, fontWeight: 500, wordBreak: "break-all" }}
          >
            {value as string}
          </p>
          <button
            className="button is-link is-light is-small"
            onClick={handleEditClick}
            style={{ border: "none", backgroundColor: "transparent" }}
            title="Edit"
          >
            <span
              className="icon is-small"
              style={{ fontSize: "11px", marginBottom: "5px" }}
            >
              <i className="fas fa-pencil-alt" style={{ color: "grey" }}></i>
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default InlineEdit;
