import firebase_app from "../config";
import {
  signInWithEmailAndPassword,
  getAuth,
  signOut as firebaseSignOut,
} from "firebase/auth";

const auth = (!!process.env.REACT_APP_FIREBASE_API_KEY
  ? getAuth(firebase_app)
  : null) as unknown as any;

export const signIn = async (email: string, password: string) => {
  let result = null,
    error = null;
  try {
    result = await signInWithEmailAndPassword(auth, email, password);
  } catch (e) {
    error = e;
  }

  return { result, error };
};

export const signOut = async () => {
  let result = null,
    error = null;
  try {
    result = await firebaseSignOut(auth);
  } catch (e) {
    error = e;
  }

  return { result, error };
};
