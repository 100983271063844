import { useEffect, useState } from "react";
import { MetaResult } from "../generated/types";
import { get } from "./http";

const useFetching = <T>(_path: string, params: any = {}, deps: any = []) => {
  const [path] = useState(_path);
  const [data, setData] = useState<T | null>(null);
  const [meta, setMeta] = useState<MetaResult | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onLoadData = async () => {
    setIsLoading(true);
    const { data, meta } = await get<T>(path, params);
    setData(data);
    setMeta(meta);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => onLoadData())();
  }, [path, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, setData, meta, isLoading, onLoadData };
};

export default useFetching;
