"use client";

import { useContext, useEffect } from "react";
import { useNavigate } from "../utils/navigationUtils";
import { UserContext } from "../providers/user_provider";

const HomeNextPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const to = user ? "/users" : "/auth/sign-in";

  useEffect(() => {
    navigate(to);
  }, [navigate, to]);

  return null;
};

export default HomeNextPage;
