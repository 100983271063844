"use client";

import React, { useEffect, useState } from "react";
import AuctionRoom from "../../Components/AuctionRoom";
import * as backend from "../../Backend";
import { Lot } from "../../generated/types";

const AuctionRoomsNextPage = () => {
  const [activeLot, setActiveLot] = useState<Lot | null>(null);

  useEffect(() => {
    backend.lot
      .upcoming()
      .then((upcomingLots) => setActiveLot(upcomingLots[0]));
  }, []);

  if (!activeLot) {
    return <div>Loading...</div>;
  }

  return <AuctionRoom activeLot={activeLot!} />;
};

export default AuctionRoomsNextPage;
