import React from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import NewComparable from "./components/NewComparable";
import useComparablesSection from "./useComparablesSection";
import VimeoFormModal from "../components/VimeoFormModal";
import ComparableItem from "./components/ComparableItem";
import { Lot } from "../../../../generated/types";
import useLot from "../useLot";
import { useToast } from "../../../../providers/notification.provider";
import InlineEdit from "../../../InlineEdit";
import BulkUploadModal from "../components/BulkUploadModal";

interface ComparablesSectionProps {
  lot: Lot;
  setVimeoDeleteAction: (video: Array<any>) => void;
  setVimeoVideo: (video: any) => void;
}

const ComparablesSections: React.FC<ComparablesSectionProps> = ({
  lot,
  setVimeoDeleteAction,
  setVimeoVideo,
}) => {
  const toast = useToast();
  const {
    comparables,
    setShowComparables,
    showComparables,
    reorderComparables,
    setReorderComparables,
    comparablesPositions,
    setComparablesPositions,
    saveComparableOrdering,
    onAddComparable,
    onEditComparable,
    onDeleteComparable,
    showNewComparable,
    setShowNewComparable,
    setNewComparable,
    newComparableDescription,
    setNewComparableDescription,
    newComparableRow,
    setNewComparableRow,
    saving,
    uploadComparable,
    deleteLotComparableVimeo,
    newVimeoForComparableAt,
    setNewVimeoForComparableAt,
    updateLotComparableVimeo,
    handleDragEnd,
    row1Items,
    row2Items,
    bulkUpload,
    showBulkModal,
    setShowBulkModal,
    hideWhitespaceComparables,
    setHideWhitespaceComparables,
  } = useComparablesSection(lot, toast, setVimeoDeleteAction);
  const { updateLot } = useLot(lot.id);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 10 },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return (
    <>
      <VimeoFormModal
        visible={newVimeoForComparableAt > -1 && !!comparables}
        onCancel={() => setNewVimeoForComparableAt(-1)}
        onConfirm={updateLotComparableVimeo}
      />

      <BulkUploadModal
        visible={showBulkModal}
        setVisible={setShowBulkModal}
        onConfirm={bulkUpload}
        rowsEnabled
      />

      <NewComparable
        {...{
          showNewComparable,
          setShowNewComparable,
          setNewComparable,
          newComparableDescription,
          setNewComparableDescription,
          newComparableRow,
          setNewComparableRow,
          saving,
          uploadComparable,
        }}
      />

      <h2>
        Comparables section{" "}
        <span
          className="as_link"
          onClick={() => setShowComparables(!showComparables)}
        >
          {showComparables ? "Hide" : "Show"}
        </span>
      </h2>

      {showComparables ? (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <div className="box">
            <h2>Row 1</h2>
            <InlineEdit
              label="Comparables Section 1 Title"
              name="comparablesSectionTitle"
              initialValue={lot.comparablesSectionTitle || "-"}
              onSubmit={updateLot}
            />
            <div
              className="field is-flex is-align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <label
                className=""
                style={{ marginRight: "0.5rem", fontWeight: "bold" }}
              >
                Hide whitespace in carousel section 1:
              </label>

              <div
                className="control is-flex is-align-items-center"
                style={{ gap: "0.25rem" }}
              >
                <input
                  type="checkbox"
                  checked={hideWhitespaceComparables[0]}
                  onChange={(e) => {
                    const newHideWhitespaceComparables = [
                      ...hideWhitespaceComparables,
                    ];
                    newHideWhitespaceComparables[0] = e.target.checked;
                    setHideWhitespaceComparables(newHideWhitespaceComparables);
                    updateLot({
                      name: "hideWhitespace",
                      value: {
                        comparables: newHideWhitespaceComparables,
                        sections: lot.hideWhitespace.sections,
                      },
                    });
                  }}
                />
              </div>
            </div>

            {!!row1Items.length && (
              <>
                <div className="columns is-multiline">
                  <SortableContext
                    items={row1Items}
                    strategy={rectSortingStrategy}
                  >
                    {row1Items.map((item, index) => (
                      <ComparableItem
                        key={item.id}
                        comparable={item}
                        index={index}
                        reorderComparables={reorderComparables}
                        comparablesPositions={comparablesPositions}
                        setComparablesPositions={setComparablesPositions}
                        setVimeoVideo={setVimeoVideo}
                        setNewVimeoForComparableAt={setNewVimeoForComparableAt}
                        onEditComparable={onEditComparable}
                        setVimeoDeleteAction={setVimeoDeleteAction}
                        deleteLotComparableVimeo={deleteLotComparableVimeo}
                        onDeleteComparable={onDeleteComparable}
                      />
                    ))}
                  </SortableContext>
                </div>

                <hr />
              </>
            )}
            <h2>Row 2</h2>
            <InlineEdit
              label="Comparables Section 2 Title"
              name="byArtistSectionTitle"
              initialValue={lot.byArtistSectionTitle || "-"}
              onSubmit={updateLot}
            />

            <div
              className="field is-flex is-align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <label
                className=""
                style={{ marginRight: "0.5rem", fontWeight: "bold" }}
              >
                Hide whitespace in carousel section 2:
              </label>

              <div
                className="control is-flex is-align-items-center"
                style={{ gap: "0.25rem" }}
              >
                <input
                  type="checkbox"
                  checked={hideWhitespaceComparables[1]}
                  onChange={(e) => {
                    const newHideWhitespaceComparables = [
                      ...hideWhitespaceComparables,
                    ];
                    newHideWhitespaceComparables[1] = e.target.checked;
                    setHideWhitespaceComparables(newHideWhitespaceComparables);
                    updateLot({
                      name: "hideWhitespace",
                      value: {
                        comparables: newHideWhitespaceComparables,
                        sections: lot.hideWhitespace.sections,
                      },
                    });
                  }}
                />
              </div>
            </div>

            {!!row2Items.length && (
              <>
                <div className="columns is-multiline">
                  <SortableContext
                    items={row2Items}
                    strategy={rectSortingStrategy}
                  >
                    {row2Items.map((item, index) => (
                      <ComparableItem
                        key={item.id}
                        comparable={item}
                        index={index}
                        reorderComparables={reorderComparables}
                        comparablesPositions={comparablesPositions}
                        setComparablesPositions={setComparablesPositions}
                        setVimeoVideo={setVimeoVideo}
                        setNewVimeoForComparableAt={setNewVimeoForComparableAt}
                        onEditComparable={onEditComparable}
                        setVimeoDeleteAction={setVimeoDeleteAction}
                        deleteLotComparableVimeo={deleteLotComparableVimeo}
                        onDeleteComparable={onDeleteComparable}
                      />
                    ))}
                  </SortableContext>
                </div>
              </>
            )}
          </div>
          <div className="buttons is-right">
            {reorderComparables ? (
              <>
                <button
                  className="button is-danger is-light"
                  onClick={() => setReorderComparables(false)}
                >
                  Cancel
                </button>
                <button
                  className="button is-primary"
                  onClick={() => saveComparableOrdering()}
                >
                  Save Ordering
                </button>
              </>
            ) : (
              <>
                <button
                  className="button"
                  onClick={() => setReorderComparables(true)}
                >
                  Reorder
                </button>
                <button className="button" onClick={onAddComparable}>
                  Add
                </button>
                <button
                  className="button"
                  onClick={() => setShowBulkModal(true)}
                >
                  Bulk upload
                </button>
              </>
            )}
          </div>
        </DndContext>
      ) : null}
    </>
  );
};

export default ComparablesSections;
